// import { getKcContextMock } from "./keycloak-theme/login/KcPageStory";
import { lazy } from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { KcPage, type KcContext } from "./keycloak-theme/kc.gen";
import { Typography } from "@mui/material";

const App = lazy(() => import("./App"));
const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

// As `kc*Context` is only set when loaded during `yarn keycloakify`,
// this'll never run in a webbrowser.
declare global {
    interface Window {
        kcContext?: KcContext;
    }
}

// The following block can be uncommented to test a specific page with `yarn dev`
// Don't forget to comment back or your bundle size will increase
// if (process.env.NODE_ENV === "development") {
//     window.kcContext = getKcContextMock({
//         pageId: "login-update-profile.ftl",
//         overrides: {}
//     });
// }

if (window.kcContext) {
    root.render(
        <KcPage
            kcContext={window.kcContext}
            fallback={<Typography variant="h4">Loading...</Typography>}
        />,
    );
} else {
    root.render(<App />);

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
}
